import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple, Video } from "../components"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import slugifyPost from "../../slugifyPost"
import slugifyPost from "../../slugifyPost"

const SingleVideoPage = ({ data, pageContext }) => {
  const { videoTitle, performer, videoText, videoLink } = data.contentfulVideo

  // const image = getImage(data.contentfulVideo.videoImage)

  const { previous, next } = pageContext

  const prevTitle = previous ? previous.videoTitle : null
  const nextTitle = next ? next.videoTitle : null
  // const prevRow = previous ? previous.data.movies_row : null
  // const nextRow = next ? next.data.movies_row : null

  const prevRow = previous
    ? slugifyPost(previous.performer.performerName)
    : null
  const nextRow = next ? slugifyPost(next.performer.performerName) : null

  const allItemPage = `/video/1`
  const oneItemPage = `/video/`

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h1>{videoTitle}</h1>
          <h2>{performer.performerName}</h2>
          <p
            className="info"
            dangerouslySetInnerHTML={{
              __html: videoText.childMarkdownRemark.html,
            }}
          ></p>

          <Video videoSrcURL={videoLink} videoTitle={videoTitle} />

          {/* <a
            href={movies_imdb}
            target="_blank"
            rel="noreferrer"
            className="btn"
          >
            <h3>Imdb: {movies_title}</h3>
            <Image fluid={fluid} className="img" />
          </a> */}
        </div>

        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-10);
  padding-top: 9rem;
  padding-bottom: 0rem;

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }

    .info {
      padding-left: 10%;
      padding-bottom: 2rem;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }

  .img {
    border-radius: var(--radius);
    transition: var(--transition);
  }

  a {
    display: block;
    width: auto;
    text-align: center;
    padding: 1rem 1rem;
    margin: 0 auto;
    ${"" /* margin-top: 1rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }

  @media (min-width: 768px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 40%;
    }
  }
`

export const query = graphql`
  query($recordId: String!) {
    contentfulVideo(contentful_id: { eq: $recordId }) {
      contentful_id
      videoTitle
      performer {
        performerName
      }
      videoText {
        childMarkdownRemark {
          html
          excerpt(format: HTML, pruneLength: 100)
        }
      }
      videoLink
      category {
        categoryName
      }
      videoImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
`

export default SingleVideoPage
